<template>
  <LoaderGlobal/>
</template>

<script>

// On importe le loader.
import LoaderGlobal from "../components/LoaderGlobal";

export default {
  name: `Lancement`,
  components: {
    LoaderGlobal,
  }
}
</script>

<style scoped>

</style>