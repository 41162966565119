<template>
  <v-container class="mt-3">

    <v-row
        align="center"
        justify="center"
    >
      <v-col
          v-for="(button, i) in buttons"
          :key="i"
          cols="12"
          sm="4"
          :v-if="button.length > 0"
      >
        <v-card
            color="white"
            height="100px"
            :to="button.to"

        >
          <div class="d-flex align-center justify-center justify-space-between fill-height" >

              <v-img
                  :src="require('@/assets/icones/'+ button.icone + '.png')"
                  :max-height="$vuetify.breakpoint.md ? 90 : 80"
                  :max-width="$vuetify.breakpoint.md ? 80 : 70"
                  class="ml-4"
              ></v-img>
              <p
                  class="primary--text text-uppercase mb-0"
                  v-text="button.titre"
              ></p>

            <div class="align-self-center">
              <v-icon class="secondary--text" size="60">mdi-chevron-right</v-icon>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  name: `EspaceMembre`,
  data: function () {
    return {
      buttons:[
        {titre:"dépot d'annonces", icone:"depot_annonce", to:"annonce"},
        {titre:"Accéder au carnet d'adresses", icone:"carnet_adresse", to:"carnet_adresse"},
        {titre:"Réserver un déjeuner", icone:"reserver_dejeuner", to:"inscription_membre_2"},
        {titre:"Historique des commandes", icone:"adresse_facturation", to:"historique"},
        {titre:"Adresse de facturation", icone:"depot_annonce", to:"facturation"},
        {titre:"Modifier mon profil", icone:"modifier_profil", to:"profil"},
        {titre:"Voir la publicité", icone:"modifier_profil", to:"publicite"},
        {titre:"Voir la galerie", icone:"modifier_profil", to:"galerie"},
      ]
    }
  },
}
</script>

<style scoped>

</style>