<template>
  <v-container>

    <v-simple-table>
      <template v-slot:default>
        <thead class="bg-secondary-reduce">
        <tr>
          <th colspan="2" class="text-left white--text">
            Liste des achats
          </th>
          <th class="text-left white--text"> &nbsp; </th>
          <th class="text-left white--text"> &nbsp; </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item, y) in $root.DATAS.commandes">
          <tr :key="y">
            <td>
              <v-img
                  :src="Jean_Michel"
                  width="50"
              >
              </v-img>
            </td>
            <td class="primary--text">{{ item.nom }}</td>
            <td>
              <v-btn
                  icon
                  color="secondary"
              >
                <v-icon>fa-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                  icon
                  color="secondary"
              >
                <v-icon>fa-download</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr
              v-for="option in item.options"
              :key="option"
              class="primary--text"
          >
            <td></td>
            <td>Option du déjeuner: {{ option.nom }}</td>
            <td>{{ option.prix}}€</td>
          </tr>
        </template>
        </tbody>

      </template>
    </v-simple-table>

  </v-container>
</template>

<script>

import Jean_Michel from '../assets/jean_michel.webp'

export default {
  name: `Historique`,
  data: function () {
    return {
      Jean_Michel,
    }
  },
}
</script>

<style scoped>
.bg-secondary-reduce{
  background: rgba(202, 191, 166, .80);
}

/* Met un striped rows sur le tableau. */
tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .05);
}
</style>