<template>
  <v-container class="text-center">
    <v-col
        cols="12"
        class="align-center justify-center text-center"
    >
      <v-text-field
          label="Rechercher un club par nom, par ville"
          outlined
          dense
          filled
          solo
          flat
          hide-details
          background-color="primary"
          text-color="white"
          dark
          class="pa-0 ma-0"
          v-model="clubFilter"
          clearable
      ></v-text-field>
    </v-col>

    <v-col
        cols="12"
        v-if="filteredClubs.length == 0"
    >
      <v-card color="white">
        <v-alert type="error">
          Aucun club ne correspond à votre recherche !
        </v-alert>
      </v-card>
    </v-col>
    <v-row>
      <v-col
          v-for="(club, i) in filteredClubs"
          :key="i"
          cols="12"
          sm="4"
          :v-if="filteredClubs.length > 0"
      >
        <v-card
          color="white"
          @click="$root.followAClub(club.id)"
        >
          <div class="d-flex align-center justify-center justify-space-between">
              <v-img
                  :src="'https://businessclub-hbc.e-systemes.fr/assets/global/img/logo/'+club.acronym+'-web.png'"
                  :max-height="$vuetify.breakpoint.md ? 60 : 50"
                  :max-width="$vuetify.breakpoint.md ? 50 : 40"
                  class="ml-4 grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <div>
              <v-card-title
                  class="primary--text"
                  v-text="club.city"
              ></v-card-title>

              <v-card-subtitle class="secondary--text" v-text="(club.city + ' Business Club')"></v-card-subtitle>

            </div>
            <div class="align-self-center">
              <v-icon class="secondary--text" size="60">mdi-chevron-right</v-icon>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';

export default {
  name: "ChoixDuClub",
  computed: {
    filteredClubs: function () {

      // Récupération des DATAS du JSON.
      let DATAS = this.$root.DATAS;

      // On filtres les clubs par le filtre.
      let clubs_filtered_by_text = DATAS.clubs.filter(club => club.city.toLowerCase().includes(this.clubFilter.toLowerCase()));

      // Si il n'y as pas de filtres.
      if(this.clubFilter === "") {
        clubs_filtered_by_text = DATAS.clubs;
      }

      // On retourne les données livrées.
      return _.orderBy(clubs_filtered_by_text, 'city');
    }
  },
  data: function () {
    return {
      clubFilter: "",
    }
  }
}
</script>

<style scoped>
.custom-placeholer-color input::placeholder {
  color: red!important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: red;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input{
  color: red!important;
}
</style>