<template>
    <!-- Arrière plan - Image -->
    <v-img
        style="height: calc(100% + 12px) !important;"
        :src="ImagePub"
        position="left"
        class="text-right pa-2"
    >
      <v-btn
          icon
          color="pink"
          outlined
          to="/"
      >
        <v-icon>fa-times</v-icon>
      </v-btn>
    </v-img>
</template>

<script>
import ImagePub from '../assets/lidle-pub.jpeg'

export default {
  name: `Accueil`,
  data: function () {
    return {
      ImagePub
    }
  },
}
</script>

<style scoped></style>