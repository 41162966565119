<template>
  <v-container class="mt-3">

    <p class="primary--text"><b>Filtrer les sociétés ({{ filteredSocieties.length }})</b></p>

    <v-btn-toggle tile group v-model="filtre">
      <v-btn
          v-for="(item, i) in items"
          :key="i"
          text
          :class="'text-uppercase secondary--text ' + (filtre === item ? 'v-btn--active': '')"
          active-class="primary--text"
      >
        {{ item.titre }}
      </v-btn>
    </v-btn-toggle>

    <v-container
        class="grey lighten-5"
    >
      <v-row>
        <v-text-field
            placeholder="Recherchez par nom, par entreprise ..."
            background-color="rgba(202, 191, 166, .80)"
            class="pa-0 ma-0"
            filled
            flat
            dense
            primary
            v-model="societieFilter"

            style="border-bottom-right-radius: 0px !important;"
        >
        </v-text-field>
        <v-btn
            height="40"
            class="secondary primary--text font-weight-bold text-uppercase"
            elevation="0"
            style="border-radius: 0px"
        > rechercher </v-btn>
      </v-row>

      <v-row>
        <v-col
            v-for="(societe, n) in filteredSocieties"
            :key="n"
            class="d-flex child-flex pa-1"
            cols="6"
        >
          <v-card
              class="mx-auto"
              elevation="0"
              :to="'societe/' + societe.id"
          >
          <v-img
              :src="'https://businessclub-hbc.e-systemes.fr/img/200/200/society/'+societe.id+'/' + societe.logoUrl"
              aspect-ratio="1"
              class="grey lighten-2"
              contain
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>


          <p class="text-uppercase mb-0 mt-2"><b>{{societe.name}}</b></p>
            <small>{{societe.activity.name}}</small>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
import _ from 'lodash';

export default {
                //:src="'https://businessclub-hbc.e-systemes.fr/img/340/250/society/'+societe.id+'/' + societe.logoUrl"-->

    // eslint-disable-next-line vue/multi-word-component-names
  name: "Societes",
  computed: {
    filteredSocieties: function () {

      let $scope = this;
      // Récupération des DATAS du JSON.
      let DATAS = this.$root.DATAS;

      let clubs_filtered_by_text = DATAS.societes;

      if($scope.filtre == undefined){
        $scope.filtre = 0;
      }

      // Si il n'y as pas de filtres.
      if(this.societieFilter !== "") {

        // On filtres les clubs par le filtre.
        clubs_filtered_by_text = DATAS.societes.filter(function(societe){
          // eslint-disable-next-line no-console
          console.debug("CLUB " + societe.club.id, $scope.$root.current_club_id)
          if(societe.club.id != $scope.$root.current_club_id){

            return false;
          }

          if($scope.items[$scope.filtre].sous_field != null){
            return societe[$scope.items[$scope.filtre].field][$scope.items[$scope.filtre].sous_field].toLowerCase().includes($scope.societieFilter.toLowerCase());
          }else{
            return societe[$scope.items[$scope.filtre].field].toLowerCase().includes($scope.societieFilter.toLowerCase());
          }
        });
      }

      // On retourne les données livrées.
      return _.orderBy(clubs_filtered_by_text, 'name');
    }
  },
  data: function () {
    return {
      societieFilter: "",
      items: [
        {
          titre: "Nom",
          field: "name",
          sous_field: null,
        },
        {
          titre: "Société",
          field: "customerCode",
          sous_field: null,
        },
        {
          titre: "Activité",
          field: "activity",
          sous_field: "name",
        }
      ],
      filtre: 0, // Fitre actuelle.
    }
  },
}
</script>

<style scoped>

p{
  line-height: 1;
}

.v-text-field--filled {
  border-radius: 0px !important;
}

</style>