<template>
  <div>
    <v-img
        :src="'https://businessclub-hbc.e-systemes.fr/img/200/200/society/'+societe.id+'/' + societe.logoUrl"
        height="250"
        class="grey lighten-2"
    >
      <template v-slot:placeholder>
        <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
        >
          <v-progress-circular
              indeterminate
              color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-container>

      <h1>{{ societe.name }}</h1>

      <h5 class="text-decoration-underline"> Membre(s) du club</h5>
      <v-row v-if="societe.membres">
        <v-col class="mt-2" cols="6" v-for="(membre, i) in societe.membres" :key="i">

          <p class="mb-0 pb-0 text-uppercase secondary--text" style="line-height: 1">{{membre.prenom}} {{membre.nom}}</p>
          <p class="mb-0 pb-0" style="line-height: 1">{{membre.poste}}</p>
        </v-col>
      </v-row>

        <v-row>
          <v-col>
            <v-img
                max-height="150px"
                max-width="180px"
                class="grey lighten-2"
                :src="'https://businessclub-hbc.e-systemes.fr/img/200/200/society/'+societe.id+'/' + societe.logoUrl"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col>
            <div>
              <p class="mb-0 pb-0" style="line-height: 1">{{ societe.activity.name}}</p>
              <p class="mb-0 pb-0" style="line-height: 1">{{ societe.headcount.name}} collaborateur(s)</p>
              <p class="mb-0 pb-0" style="line-height: 1">{{ societe.turnover}}€ de CA</p>
            </div>
          </v-col>
        </v-row>

      <p class="mt-5"><small>{{ societe.description }}</small></p>


    </v-container>
  </div>

</template>

<script>
export default {
  name: `Societe`,
  mounted(){
    let $scope = this;
    this.societe2 = this.$root.DATAS.societes.filter(societe => societe.id == $scope.$route.params.id);
  },
  computed: {
    societe: function(){
      let $scope = this;
      return $scope.$root.DATAS.societes.filter(societe => societe.id == $scope.$route.params.id)[0];
    }
  },
  data: function () {
    return {
      societe2: null,
    }
  },
}
</script>

<style scoped>

</style>