<template>
  <div>
    <v-img
        :src="dejeuner.href"
        height="250"
    >
    </v-img>

    <v-container>
      <p class="primary--text mb-2">Merci de remplir ce formulaire pour vous inscrire au déjeuner</p>
      <p class="mb-2"><b>Votre participation au déjeuner ne sera effective qu'après confirmation de notre part.</b></p>
      <p>Participation de 66€/pers. couvrant les frais de repas.</p>
      <p class="error--text">Tous les champs de ce formulaire sont obligatoires.</p>


      <!-- Nom de la société -->
      <p class="primary--text mt-4">Nom de la société</p>
      <v-text-field
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>

      <!-- Secteur d'activité -->
      <p class="primary--text">Secteur d'activité</p>
      <v-text-field
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>

      <!-- Nom -->
      <p class="primary--text">Nom</p>
      <v-text-field
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>

      <!-- Prénom -->
      <p class="primary--text">Prénom</p>
      <v-text-field
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>

      <!-- Adresse -->
      <p class="primary--text">Adresse</p>
      <v-text-field
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>

      <!--Code postale -->
      <p class="primary--text">Code postale</p>
      <v-text-field
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>

      <!-- Téléphone -->
      <p class="primary--text">Téléphone</p>
      <v-text-field
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>

      <!-- Email -->
      <p class="primary--text">E-mail</p>
      <v-text-field
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>


    </v-container>
    <div class="text-center">

      <v-btn
          class="ma-0 mb-5 primary--text font-weight-bold"
          color="secondary"
          tile
          to="inscription_membre"
      >
        Je me connecte
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: `Inscription_Membre`,
  data: function () {
    return {
      dejeuner:
          {
            "nom": "Fauvergue",
            "prenom" : "Jean Michel",
            "sous_titre": "Ancien ministre de l'intérieur, Ancien Président de l'Assemblée Nationale et du Conseil Constitutionnel",
            "description": "Comme une entreprise, le RAID s'adapte à chaque instant pour tendre vers l'excellence. C'est une aptitude qui a permis le succès et la libération de tous les otages de l'Hypercacher de Vincennes.",
            "date": "16 juin 2021",
            "ville": "Mulhouse",
            "salle": "au stade du Hainaut",
            "href": "https://img.20mn.fr/28N_nQI9SmSxenulAY2Q9A/830x532_jean-michel-fauvergue-bureau-2013.jpg"
          },
    }
  },
}
</script>

<style scoped>

.bg-secondary-reduce{
  background: rgba(202, 191, 166, .80);
}

p{
  margin-bottom: 0px;
}

.bg-sombre-transparent{
  background: linear-gradient(
  rgba(0, 0, 0, 0.7),
  rgba(0, 0, 0, 0.5)
  );
}

</style>