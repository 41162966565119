<template>

  <div>
    <!-- Image d'illustration -->
    <v-img
        :src="Logo"
        height="250"
    >
    </v-img>

    <v-container>
      <p><b>Merci pour votre commande. <br>Vous avez toujours la possibilité de convier jusqu'à 3 invités au tarif de PRIX par invité.</b></p>

      <p>Rendez-vous le <span class="secondary--text"> DATE / HEURE à LIEU </span></p>

      <p>Le déjeuner est organisé dans le respect des gestes barrières liés à la crise sanitaire actuelle.</p>

      <p>Port du masque obligatoire.</p>

      <p>L'accès aux déjeuners sera conditionné à la présentation d'un pass sanitaire.
      Tout déjeuner annulé moins de 72h jours ouvrés (hors samedi et dimanche) avant l'événement sera facturé.</p>

      <v-btn
          class="ma-0 primary--text font-weight-bold"
          color="secondary"
          tile
          to="/"
      >
        retour à l'accueil
      </v-btn>
    </v-container>
  </div>

</template>

<script>
import Logo from '../assets/logo_penche.jpeg'

export default {
  name: "ConfirmationCommande",
  data: function () {
    return {
      Logo,
    }
  },

}
</script>

<style scoped>

</style>