<template>
  <v-container class="mt-10">
    <Galerie2></Galerie2>
  </v-container>
</template>

<script>
import Galerie2 from "@/components/Galerie";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "GalerieView",
  components: {Galerie2}

}
</script>

<style scoped>

</style>