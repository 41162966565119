<template>
  <div>
    <!-- Image d'illustration -->
    <v-img
        :src="Logo"
        height="250"
        cover
    >
    </v-img>

    <v-container>

      <v-simple-table>
        <template v-slot:default>
          <thead class="bg-secondary-reduce">
          <tr>
            <th colspan="2" class="text-left white--text">
              Détails de la commande
            </th>
            <th class="text-left white--text">
              Prix
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in $root.DATAS.recapitulatif_commande">
            <tr :key="item.nom">
              <td>
                <v-img
                    :src="Jean_Michel"
                    width="50"
                >
                </v-img>
              </td>
              <td class="primary--text">{{ item.nom }}</td>
              <td class="primary--text">{{ item.prix }}€</td>
            </tr>
            <tr
                v-for="(invite, i) in item.invites"
                :key="invite"
                class="primary--text"
              >
              <td></td>
              <td>Invité {{ i + 1 }} : {{ invite }}</td>
              <td>{{ item.prix}}€</td>
            </tr>
            <tr
                v-for="option in item.options"
                :key="option"
                class="primary--text"
            >
              <td></td>
              <td>Option du déjeuner: {{ option.nom }}</td>
              <td>{{ option.prix}}€</td>
            </tr>
          </template>
          </tbody>
          <tfoot class="primary white--text">
            <tr>
              <th colspan="2">Total de la commande</th>
              <td>299€</td>
            </tr>
          </tfoot>

        </template>
      </v-simple-table>


      <p><small class="text--primary">Séléctionnez votre moyen de paiement : </small></p>

      <v-row dense>
        <v-col
            v-for="(card, i) in cards"
            :key="i"
            cols="6"
        >
          <v-card to="confirmation_commande">
            <v-img
                :src="card.img"
                class="white--text align-end"
                height="150px"
                contain
            >
            </v-img>
          </v-card>
        </v-col>
      </v-row>

    </v-container>

  </div>

</template>

<script>
import Logo from '../assets/logo_penche.jpeg';
import Jean_Michel from '../assets/jean_michel.webp';
import CB from '../assets/payment_cb.png';
import PACK from '../assets/payement_pack.png';

export default {
  name: "RecapitulatifCommande",
  data: function () {
    return {
      Logo,
      Jean_Michel,
      cards: [
          {
            name: 'payement_cb',
            img: CB
          },
          {
            name: 'payement_pack',
            img: PACK,
          },
    ]
    }
  },
}
</script>

<style scoped>

th{
  height: 25px !important;
}

.bg-secondary-reduce{
  background: rgba(202, 191, 166, .80);
}

/* Met un striped rows sur le tableau. */
tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .05);
}

</style>