<template>

  <v-container>

    <p>Le 13/05/2020</p>

    <v-img :src="require('@/assets/françois.jpeg')"></v-img>

    <p>Ce joueur de tennis d'exception a partagé avec les membres du PSBF et les visieteurs des moments forts de sa vie, des anecdotes racontées avec tout son talent d'orateur provoquant rires et émotions dans la salle.
    Un immense merdi Henri, les participants se souviendront longtemps de l'accueil au PSBC avec toi en première ligne !
    On se retrouve en octore, avec le président de l'Esonne, François Durovray.</p>

    <v-img :src="require('@/assets/françois.jpeg')"></v-img>

    <p>Henri Leconte et Patrick Dupont (Société)</p>

    <!-- Galerie -->
    <div class="text-center">

      <Galerie></Galerie>

      <v-btn
          class="ma-0 mb-5 primary--text font-weight-bold mt-5"
          color="secondary"
          tile
          to="galerie"
      >
        Voir la galerie complète
      </v-btn>
    </div>



  </v-container>

</template>

<script>
import Galerie from "@/components/Galerie";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Actualite",
  components: {Galerie}
}
</script>

<style scoped>

</style>