<template>
  <v-container>
  <p>Liste des inscriptions à un déjeuner</p>
  </v-container>
</template>

<script>
export default {
  name: `ListeInscriptions`
}
</script>

<style scoped>

</style>