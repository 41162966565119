<template>
  <v-container>

    <h4 class="primary--text">Modifier mon profil</h4>

    <v-container class="primary white--text mt-4">

      <p class="ma-0 pa-0" style="line-height: 1.2">Votre cotisation prend fin le <span class="secondary--text"><b>JJ/MM/AAAA</b></span></p>
      <p class="pa-0 ma-0" style="line-height: 1.2">Solde de votre pack déjeuner: &nbsp;<span class="secondary--text"><b>X déjeuners restants</b></span></p>

      <!-- Galerie -->
      <div class="text-left">
        <v-btn
            class="ma-0 mb-5 primary--text font-weight-bold mt-5"
            color="secondary"
            tile
        >
          Commander un pack déjeuner
        </v-btn>
      </div>

    </v-container>

    <h4 class="primary--text mt-5">Mon profil :</h4>

    <!-- Civilité -->
    <p class="mt-2">Civilité</p>
    <v-select
        background-color="rgba(202, 191, 166, .30)"
        :items="items"
        solo
    ></v-select>

    <!-- Nom -->
    <p class="mt-2">Nom</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .90)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
        disabled
    ></v-text-field>

    <!-- prénom -->
    <p class="mt-2">Prénom</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .90)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
        disabled
    ></v-text-field>

    <!-- Email -->
    <p class="mt-2">Email</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .90)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
        disabled
    ></v-text-field>

    <!-- Portable -->
    <p class="mt-2">Portable</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <!-- Date de naissance -->
    <p class="mt-2">Date de naissance</p>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="date"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="date"
          background-color="rgba(202, 191, 166, .30)"
          :active-picker.sync="activePicker"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
          min="1950-01-01"
          @change="save"
      ></v-date-picker>
    </v-menu>

    <!-- Tailles POLO -->
    <p class="mt-2">Taille de polo</p>
    <v-select
        background-color="rgba(202, 191, 166, .30)"
        :items="tailles"
        label="Taille de polo"
        solo
    ></v-select>
    <div class="text-center">

      <v-btn
          class="ma-0 primary--text font-weight-bold"
          color="secondary"
          tile
      >
        Enregistrer
      </v-btn>
    </div>

  </v-container>
</template>

<script>

export default {
  name: `Profil`,
  data: function () {
    return {
      items: ["Monsieur", "Madame"],
      tailles: ["L", "M", "S", "XXL"],
      activePicker: null,
      date: null,
      menu: false,
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>

<style scoped>
</style>