<template>

  <v-container>

     <p class="primary--text"><b>Filtrer les actualités</b></p>

    <v-btn-toggle tile group v-model="filtre">
       <v-btn
           v-for="(item, i) in items"
           :key="i"
           text
           class="text-uppercase secondary--text"
           active-class="primary--text"
       >
        {{ item.titre }}
       </v-btn>
    </v-btn-toggle>


    <v-container
        class="grey lighten-5"
        v-for="actualite in getActualites"
        :key="actualite.titre"
    >
      <v-card to="actualite" elevation="0">
        <v-row no-gutters >
          <v-col cols="3">
            <v-img :src="actualite.image"></v-img>
          </v-col>
          <v-col cols="9">

            <v-container class="pt-0 pb-0 custom-text">
              <span class="text--truncate"><b> {{ actualite.titre | truncate(30, '...') }}</b></span>
              <p><small>{{ actualite.description | truncate(90, '...') }}</small></p>
            </v-container>

          </v-col>
        </v-row>
      </v-card>
    </v-container>

   </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Actualites",
  computed: {
    // a computed getter
    getActualites: function () {
      // Récupération des DATAS du JSON.
      let DATAS = this.$root.DATAS;
      // Récupération du filtre.
      let filtre = this.items[this.filtre];
      return filtre != undefined && this.filtre != 0 ? DATAS.actualites.filter(e=>e[filtre.field] == true) : DATAS.actualites;
    }
  },
  data: function () {
    return {
      items: [
        {
          titre: "Tout",
          field: null,
        },
        {
          titre: "Mon club",
          field: "mon_club",
        },
        {
          titre: "Reseau",
          field: "reseau",
        },
        {
          titre: "Retour sur",
          field: "retour",
        }
      ],
      filtre: null,
    }
  },
}
</script>

<style scoped>

.custom-text{
  line-height: 1 !important;
}

</style>