<template>
  <v-container>
    <p class="font-weight-bold">Je suis un titre</p>
    <p><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</small></p>

    <!-- Publication -->
    <p class="mt-2">Publication</p>
    <v-select
        background-color="rgba(202, 191, 166, .30)"
        :items="items"
        label="Publication"
        solo
    ></v-select>

    <!-- Identifiant -->
    <p class="mt-2">Titre de l'annonce</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <!-- Descriptif -->
    <p class="mt-2">Descriptif</p>
    <v-textarea
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-textarea>

    <!-- Nom du contact -->
    <p class="mt-2">Titre de l'annonce</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <!-- Adresse e-mail du contact -->
    <p class="mt-2">Adresse e-mail du contact</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <!-- Téléphone du contact -->
    <p class="mt-2">Téléphone du contact</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <div class="text-center">

      <v-btn
          class="ma-0 primary--text font-weight-bold"
          color="secondary"
          tile
      >
        Déposer
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: `DepotAnnonce`,
  data: function () {
    return {
      items: ["locale", "nationale"],
    }
  }
}
</script>

<style scoped>

</style>