<template>
    <!-- Arrière plan - Image -->
<div>
  <v-container v-for="dejeuner in this.$root.DATAS.dejeuners" :key="dejeuner.nom">
    <v-img
        :src="dejeuner.href"
        height="200"
    >
    </v-img>
    <div class="primary px-2 pa-1">
      <span class="secondary--text m-2 p-2"><b>{{ dejeuner.date }} - </b></span>
      <span class="white--text text-uppercase"> <b> &nbsp;{{ dejeuner.ville }}</b></span>
    </div>
    <div class="bg-secondary-reduce pa-2">
      <h3 class="primary--text">{{ dejeuner.prenom }} <b><span class="text-uppercase">{{ dejeuner.nom }}</span></b></h3>

      <h4 class="primary--text font-weight-medium">
        {{ dejeuner.sous_titre }}
      </h4>
      <small class="primary--text mt-2">
      "{{ dejeuner.description }}"
      </small>

      <div class="text-center mt-2">
        <v-btn
            class="ma-0 primary--text font-weight-bold"
            color="secondary"
            tile
            to="dejeuner"
        >
          Je m'inscris

        </v-btn>
      </div>
    </div>


  </v-container>
</div>
</template>

<script>
export default {
  name: `Programmation`,
  data: function () {
    return {}
  },
}
</script>

<style scoped>

.bg-secondary-reduce{
  background: rgba(202, 191, 166, .80);
}

.bg-sombre-transparent{
  background: linear-gradient(
  rgba(0, 0, 0, 0.7),
  rgba(0, 0, 0, 0.5)
  );
}

</style>