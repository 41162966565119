<template>
  <v-container>

    <p class="primary--text"><b>Trier par</b></p>

    <v-btn-toggle tile group v-model="filtre">
      <v-btn
          v-for="(item, i) in items"
          :key="i"
          text
          class="text-uppercase secondary--text"
          active-class="primary--text"
      >
        {{ item.titre }}
      </v-btn>
    </v-btn-toggle>

    <v-container
        class="grey lighten-5"
        v-for="actualite in getActualites"
        :key="actualite.titre"
    >


      <v-row>
        <v-text-field
            placeholder="Recherchez par nom, par entreprise ..."
            background-color="rgba(202, 191, 166, .80)"
            class="pa-0 ma-0"
            filled
            flat
            dense
            primary
        >
        </v-text-field>
        <v-btn height="40" class="secondary primary--text font-weight-bold text-uppercase"> rechercher </v-btn>
      </v-row>

      <v-row>
        <v-col
            v-for="n in 20"
            :key="n"
            class="d-flex child-flex pa-1"
            cols="6"
        >
          <v-card
              :loading="loading"
              class="mx-auto"
              elevation="0"
          >
            <v-row>
              <v-col cols="4">
                <v-img
                    max-height="80px"
                    max-width="50px"
                    :src="actualite.image"
                ></v-img>
              </v-col>
              <v-col class="ml-0 pl-0">
                <div>
                  <p class="mb-0 pb-0 font-weight-bold" style="line-height: 1">{{ actualite.titre}}</p>
                  <p class="mb-0 pb-0" style="line-height: 1">{{ actualite.societe}}</p>
                  <p class="mb-0 pb-0" style="line-height: 1"><small>{{ actualite.secteur_activite}}</small></p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Societes",
  computed: {
    // a computed getter
    getActualites: function () {

      // Récupération des DATAS du JSON.
      let DATAS = this.$root.DATAS;

      // Récupération du filtre.
      let filtre = this.items[this.filtre];
      return filtre != undefined && this.filtre != 0 ? DATAS.membres.filter(e=>e[filtre.field] == true) : DATAS.membres;
    }
  },
  data: function () {
    return {
      items: [
        {
          titre: "Nom",
          field: null,
        },
        {
          titre: "Société",
          field: "mon_club",
        },
        {
          titre: "Activité",
          field: "reseau",
        }
      ],
      loading: false,
      filtre: null, // Fitre actuelle.
    }
  },
}
</script>

<style scoped>

p{
  line-height: 1;
}

</style>