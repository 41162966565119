<template>
  <div>
    <v-img
        :src="dejeuner.href"
        height="250"
    >
    </v-img>

    <v-container>
      <p class="primary--text">Vous avez la possibilité de convier jusqu'a 3 dirigeants à ce déjeuner au tarif de XX€ par personne (limité à 3 invités), souhaitez-vous en profiter&nbsp;:</p>

      <v-checkbox
          class="mt-0"
          @change="manageChange($event,1)"
          v-model="checkbox1"
          label="Oui, je souhaite inviter un ou plusieurs dirigeants"
      ></v-checkbox>

      <v-checkbox
          class="mt-0"
          @change="manageChange($event,2)"
          v-model="checkbox2"
          label="Non, je viendrai seul"
      ></v-checkbox>

      <v-card elevation="2" v-if="checkbox1">
        <v-card-actions>
          <v-list-item class="grow">

            <v-list-item-content>
              <v-list-item-title><b>Invité 1: </b>Sarah Connor</v-list-item-title>
            </v-list-item-content>

            <v-row
                align="center"
                justify="end"
            >
              <v-icon class="mr-1 text--primary" disabled>
                fa-edit
              </v-icon>
              <v-icon class="mr-1 text--primary" disabled>
                fa-times
              </v-icon>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    <v-container>

<v-row>
      <v-btn
          class="ma-0 mt-8 primary--text font-weight-bold"
          color="secondary"
          tile
          to=""
          :disabled="checkbox2"
      >
        Ajouter un autre invité
      </v-btn>
</v-row>
      <v-row>
      <v-btn
          class="ma-0 mt-3 primary--text font-weight-bold"
          color="secondary"
          tile
          to="recapitulatif_commande"
      >
        Poursuivre l'inscription
      </v-btn>
      </v-row>
    </v-container>
  </v-container>
  </div>
</template>

<script>
export default {
  name: `Inscription_Membre`,
  methods: {
    manageChange (value, nb) {
      if(nb == 2){
        this.checkbox2 = value;
        this.checkbox1 = !value;

      }else{
        this.checkbox1 = value;
        this.checkbox2 = !value;

      }
    },
  },
  data: function () {
    return {
      checkbox1: false,
      checkbox2: true,
      dejeuner:
          {
            "nom": "Fauvergue",
            "prenom" : "Jean Michel",
            "sous_titre": "Ancien ministre de l'intérieur, Ancien Président de l'Assemblée Nationale et du Conseil Constitutionnel",
            "description": "Comme une entreprise, le RAID s'adapte à chaque instant pour tendre vers l'excellence. C'est une aptitude qui a permis le succès et la libération de tous les otages de l'Hypercacher de Vincennes.",
            "date": "16 juin 2021",
            "ville": "Mulhouse",
            "salle": "au stade du Hainaut",
            "href": "https://img.20mn.fr/28N_nQI9SmSxenulAY2Q9A/830x532_jean-michel-fauvergue-bureau-2013.jpg"
          },
    }
  },
}
</script>

<style scoped>

.bg-secondary-reduce{
  background: rgba(202, 191, 166, .80);
}

.bg-sombre-transparent{
  background: linear-gradient(
  rgba(0, 0, 0, 0.7),
  rgba(0, 0, 0, 0.5)
  );
}

</style>