<template>

  <div>
    <v-img
        :src="dejeuner.href"
        height="250"
    >
    </v-img>

    <v-container>
      <h2 class="secondary--text">{{ dejeuner.date }}</h2>
      <h4 class="secondary--text"> {{dejeuner.salle }} - <span class="text-uppercase">{{ dejeuner.ville }}</span></h4>

      <h1 class="primary--text font-weight-light pt-4" style="line-height: 1rem">{{ dejeuner.prenom }}</h1>
      <h1><span class="primary--text font-weight-bold">{{ dejeuner.nom }}</span></h1>

      <p>{{dejeuner.sous_titre}}</p>

      <small>{{dejeuner.description}}</small>
    </v-container>

    <div class="bg-secondary-reduce text-center">

        <p class="pt-3"><b>Déjà 42 inscrits</b> <a class="text--primary text-decoration-underline">(voir la liste)</a></p>
        <v-btn
            class="ma-0 primary--text font-weight-bold"
            color="secondary"
            tile
            to="inscription_membre_2"
        >
          Je suis membre, je m'inscris
        </v-btn>

        <v-btn
            class="mt-2 mb-3 secondary--text font-weight-bold"
            color="primary"
            tile
            to="inscription_visiteur"

        >
          Je m'inscris en tant que visiteur
        </v-btn>

    </div>
    <v-container>
      <small>{{dejeuner.description}}</small>
    </v-container>

  </div>



</template>

<script>
export default {
  name: `Dejeuner`,
  data: function () {
    return {
      dejeuner : this.$root.DATAS.dejeuners[0],
    }
  },
}
</script>

<style scoped>

.bg-secondary-reduce{
  background: rgba(202, 191, 166, .80);
}

</style>