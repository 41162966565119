<template>
  <v-container class="primary fill-height containerLancement">
    <v-container class="primary">
      <v-row justify="center">
        <v-img
            :src="LogoBusinessClub"
            :max-height="$vuetify.breakpoint.md ? 350 : 250"
            :max-width="$vuetify.breakpoint.md ? 300 : 200"
        ></v-img>
      </v-row>
      <v-row justify="center">
        <v-progress-linear
            class="mt-15"
            color="white accent-4"
            background-color="secondary"
            indeterminate
            rounded
            height="10"
            style="max-width: 70%"
        ></v-progress-linear>
        <v-card-text class="pb-0 text-center">
          <p class="secondary--text">
            CHARGEMENT
          </p>
        </v-card-text>
      </v-row>

    </v-container>
  </v-container>
</template>

<script>
import LogoBusinessClub from '../assets/business-club-group-logo.png'
export default {
  name: "LoaderGlobal",
  data: function () {
    return {
      LogoBusinessClub
    }
  },
}
</script>

<style scoped>

.containerLancement{
  height: 100% !important;
  align-items: center !important;
  min-width: 100% !important;
}

</style>