<template>
  <v-container>

    <h4 class="primary--text">Modifier mon adresse de facturation</h4>

    <h5 class="primary--text">Mon adresse de facturation actuelle :</h5>

    <p>Nom de la société</p>
    <p>adresse de la société</p>
    <p>00 000 Ville</p>
    <p>Pays</p>
    <p>Tél. : +33 01 23 45 67 89</p>


    <v-checkbox
        v-model="checkbox"
        label="Cochez cette case si vous souhaitez utiliser une autre adresse de facturation et remplissez les champs ce dessous."
    ></v-checkbox>

    <!-- Adresse -->
    <p class="mt-2">Adresse</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <!-- Code postal -->
    <p class="mt-2">Code postal</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <!-- Ville -->
    <p class="mt-2">Ville</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <!-- Pays -->
    <p class="mt-2">Pays</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <!-- Téléphone -->
    <p class="mt-2">Téléphone</p>
    <v-text-field
        background-color="rgba(202, 191, 166, .30)"
        class="pa-0 ma-0"
        filled
        flat
        dense
        primary
    ></v-text-field>

    <div class="text-center">

      <v-btn
          class="ma-0 primary--text font-weight-bold"
          color="secondary"
          tile
      >
        Valider
      </v-btn>
    </div>

  </v-container>
</template>

<script>

export default {
  name: `Facturation`,
  data: function () {
    return {
      checkbox: false,
    }
  },
}
</script>

<style scoped></style>