<template>
  <div>
    <v-img
        :src="dejeuner.href"
        height="250"
    >
    </v-img>

    <v-container>
      <p class="primary--text">Vous n'êtes pas encore connecté, merci de vous identifier</p>


      <!-- Identifiant -->
      <p class="secondary--text mt-8">Identifiant</p>
      <v-text-field
          placeholder="Saisissez votre adresse e-mail"
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
      ></v-text-field>

      <!-- Mot de passe -->
      <p class="secondary--text">Mot de passe</p>
      <v-text-field
          placeholder="Saisissez votre mot de passe"
          background-color="rgba(202, 191, 166, .80)"
          class="pa-0 ma-0"
          filled
          flat
          dense
          primary
          password
          type="password"
      ></v-text-field>
    </v-container>
    <div class="text-center">

      <v-btn
          class="ma-0 primary--text font-weight-bold"
          color="secondary"
          tile
      >
        Je me connecte
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: `Inscription_Membre`,
  data: function () {
    return {
      dejeuner:
          {
            "nom": "Fauvergue",
            "prenom" : "Jean Michel",
            "sous_titre": "Ancien ministre de l'intérieur, Ancien Président de l'Assemblée Nationale et du Conseil Constitutionnel",
            "description": "Comme une entreprise, le RAID s'adapte à chaque instant pour tendre vers l'excellence. C'est une aptitude qui a permis le succès et la libération de tous les otages de l'Hypercacher de Vincennes.",
            "date": "16 juin 2021",
            "ville": "Mulhouse",
            "salle": "au stade du Hainaut",
            "href": "https://img.20mn.fr/28N_nQI9SmSxenulAY2Q9A/830x532_jean-michel-fauvergue-bureau-2013.jpg"
          },
    }
  },
}
</script>

<style scoped>

.bg-secondary-reduce{
  background: rgba(202, 191, 166, .80);
}

.bg-sombre-transparent{
  background: linear-gradient(
  rgba(0, 0, 0, 0.7),
  rgba(0, 0, 0, 0.5)
  );
}

</style>